import React from "react"
import { Link } from "gatsby"
import SimpleLayout from "../components/SimpleLayout"
import SEO from "../components/seo"
import BoxCenter from "../components/Common/BoxCenter"
import { FaUserCrcle, FaCheckCircle } from "react-icons/fa"
import PremiumBanner from "../components/Images/PremiumBanner"
import { useMediaQuery } from 'react-responsive'
import classnames from "classnames"

const PremiumPage = ({uri, location}) => {

    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1024 })
    const isBigScreen = useMediaQuery({ minDeviceWidth: 1824 })
    const isTabletOrMobile = useMediaQuery({ maxWidth: 576 })

    return (<SimpleLayout withHeader={false} page="premium">
        <SEO title="Jobsmigas Premium" customHtmlClass={'premium'} />
        <div className="container mb-2 mt-5 justify-content-center align-items-center">

            <div className="row justify-content-center align-items-center mb-3">
                <div className="shadow border-top-red border-bottom-red rounded col-sm-12 col-lg-10 p-0 align-items-center">
                    <PremiumBanner style={{marginBottom:0}} />
                </div>
            </div>

            <BoxCenter styles={{
                background:'gold'
            }} padding={isTabletOrMobile ? 3 : 5}>
                <div className="row pt-4">
                    <div className="col-12 text-center mb-2">
                        <h1 className="text-center display-4 mb-4" style={{color:"#c7017e"}}> Introduce Jobsmigas Premium </h1>
                        <p className="mb-4 lead">
                        Jobsmigas is helping job seeker to find jobs easier for especially <br />if you’re seeking jobs to website jobsmigas.com.
                        </p>
                        
                        <p className="lead mb-4">
                        Good news for you all jobs seekers! jobsmigas has came up with a solution to make finding jobs easily , we call it <strong>“JOBSMIGAS PREMIUM”</strong> it will give you 4 advantages compared to normal users.
                        </p>
                    </div>

                    {isTabletOrMobile && <div className="col-12 my-3 justify-content-center text-center">
                        <div style={{
                            border:"2px dashed #ddd",
                            background:"#f9f9f9"
                        }}>
                            <Link to={'/login?red=upgrade&referrer=landing'} className="btn mt-3 mb-3 btn-danger btn-lg">Get Premium Now!</Link>
                        </div>
                    </div>}
                    
                    <div className="col-lg-7 col-12">
                        <h4 className={classnames("mb-3", {
                            'text-center':isTabletOrMobile
                        })}>Why you upgrade to JOBSMIGAS PREMIUM?</h4>
                        <ul className="mt-4" style={{listStyle:"none", paddingLeft:0}}>
                            <li className="mb-4">
                                <div className="mb-2 row">
                                <span className="col-auto"><FaCheckCircle className="" style={{color:"#c7017e"}} size={22}/></span>
                                <span className="col pl-0">
                                    <h5 style={{color:"#c7017e"}}>Jobs Notification Priority</h5> <strong>This feature will give you a job notification priority to your email if there is a vacancy that suits with Your specialist. You will get the job vacancy exclusively faster than other jobseekers</strong> </span>
                                </div>
                            </li>
                            <li className="mb-4">
                                <div className="mb-2 row">
                                <span className="col-auto"><FaCheckCircle style={{color:"#c7017e"}} size={22}/></span>
                                <span className="col pl-0">
                                    <h5 style={{color:"#c7017e"}}>News &amp; Info exclusive about recruitment, internship program, free training, project, education.</h5> <strong>Only you will get exclusively information from us about recruitment, internship programs, free training, projects, education.</strong> </span>
                                </div>
                            </li>
                            <li className="mb-4">
                                <div className="row">
                                    <span className="col-auto"><FaCheckCircle style={{color:"#c7017e"}} size={22}/> </span>
                                    <span className="col pl-0"> 
                                    <h5 style={{color:"#c7017e"}}>Access communication to Recruiter with message box</h5>
                                    <strong>You could use comfortably and safely the communication features that strive for to facilitate and speed up the communication from recruiter to you directly in one platform, with a recorded and accountable communication security system and the information obtained We could guarantee that its from HR Company directly to premium members. </strong></span>
                                </div>
                            </li>

                            <li className="mb-4">
                                <div className=" row">
                                    <span className="col-auto"><FaCheckCircle style={{color:"#c7017e"}} size={22}/> </span>
                                    <span className="col pl-0"> 
                                    <h5 style={{color:"#c7017e"}}>Result and feedback review from recruiter</h5> 
                                    <strong>The recruiter could directly give Your result through this feature without wasting your time to apply again. You just have to wait for further info from the HR Company.</strong></span>
                                </div>
                            </li>

                        </ul>
                    </div>
                    <div className="col-12 col-lg-5">
                        <div className="card shadow-sm text-center p-3" style={{
                            background:'#FFC107',
                            borderWidth:7,
                            borderStyle:"dashed",
                            borderColor:"rgba(0, 0, 0, 0.325)"
                        }}>
                            <div className="mb-3"> <h4>Subscription Fee</h4> </div>
                            {isDesktopOrLaptop && <div> 
                                <div className="d-flex justify-content-center">
                                    <div className="my-2" style={{
                                        textAlign:"center",
                                        justifyContent:"center",
                                        justifyItems:"center",
                                        alignContent:"center",
                                        alignItems:"center",
                                        height:150,
                                        width:2,
                                        borderLeftWidth:5,
                                        borderLeftStyle:"solid",
                                        borderLeftColor:"#c7017e"
                                    }}>
                                    </div>
                                </div>
                            </div>}
                            <h2 className="mt-2" style={{color:"#c7017e"}}>
                            <span className="display-4" style={{color:"#c7017e"}}> <sup>IDR</sup> 50.000</span> <br />/ 3 months + <span>{`Bonus free 2 months membership.`}</span>
                            </h2>
                            {isDesktopOrLaptop && <div> 
                                <div className="d-flex justify-content-center">
                                    <div className="my-2" style={{
                                        textAlign:"center",
                                        justifyContent:"center",
                                        justifyItems:"center",
                                        alignContent:"center",
                                        alignItems:"center",
                                        height:150,
                                        width:2,
                                        borderLeftWidth:5,
                                        borderLeftStyle:"solid",
                                        borderLeftColor:"#c7017e"
                                    }}>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>


                <div className="row mt-3 justify-content-center text-center mb-5">
                    <div className="col-10">
                        <Link to={'/login?red=upgrade&referrer=landing'} style={{padding:'20px 30px'}} className="btn mt-4 mb-4 btn-dark btn-lg">Start Premium Now!</Link>
                    </div>
                </div>
            </BoxCenter>
        </div>

    </SimpleLayout>)
}

export default PremiumPage