import React from "react"
import classnames from "classnames"
import { FaPlus, FaMinus } from "react-icons/fa"

const BoxCenter = ({bgcolor='', styles={}, title, padding=5, children, width=10, withAction=false, onAction, stateAction=false}) => (
    <div className="row justify-content-center align-items-center">
        <div style={styles} className={classnames(`shadow ${bgcolor} border-top-red rounded p-${padding} col-sm-12 col-lg-${width} mb-3 align-items-center`)}>
            <div className="row mb-2">
                {title && <div className={classnames({
                    'col-12' : withAction == false,
                    'col-8' : withAction == true
                })}>
                    <h3 className="">{title}</h3>
                </div>}
                {withAction && <div className="col-4 text-right">
                    {stateAction == false && <FaPlus style={{cursor:'pointer'}} title={`Add ` + title} onClick={onAction} size={20} />}
                    {stateAction == true && <FaMinus style={{cursor:'pointer'}} title={`Add ` + title} onClick={onAction} size={20} />}
                </div>}
            </div>
                   
            {children}
        </div>
    </div>
)

export default BoxCenter