import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const PremiumBanner = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "premium-promo-03.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 842, maxHeight:842, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
      }
    }
  `)

  return (<div>
      <Link to={`/login?red=upgrade&referrer=landing`}>
        <Img style={{marginBottom:0}} fluid={data.placeholderImage.childImageSharp.fluid} />
      </Link>
  </div>)
}

export default PremiumBanner
